import React, {
  useState, useEffect, useCallback, useContext,
} from 'react';
import { Link } from 'react-router-dom';
import { isEmpty, pick } from 'lodash';

import {
  InvoicesIcon,
  StatusCompletedIcon,
  ExcelIcon,
  StatusVoidIcon,
  StatusPartiallyPaidIcon,
  PortableDocumentIcon,
  StatusUnpaidIcon,
  StatusVoidedIcon,
  StatusRefundedIcon,
  StatusPaymentSkippedIcon,
} from 'assets/images/svg-icons';

import ListingComponent from 'components/shared/listing-component';
import JobDetails from 'components/shared/job-details';

import Filter from 'components/shared/filter/filter';
import HeaderComponent from 'components/shared/header-component';

import { LookupContext } from 'context/lookup-context/lookup-context';
import { generateRandomString, openInANewTab } from 'helpers/utils';
import JobDescriptionModal from 'components/shared/modals/job-description-modal';
import { getExportInvoices, getInvoice, getInvoiceHeader } from 'services/invoice';
import catchHandler from 'helpers/catch-handler';
import ExportModal from 'components/shared/modals/export-modal';

const Invoices = (props) => {
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({});
  const [loading, setLoading] = useState(false);
  const { location } = props;
  // const startDate = location?.state?.startDate ||
  // moment().subtract(29, 'days').format('YYYY-MM-DD');
  // const endDate = location?.state?.endDate || moment().format('YYYY-MM-DD');
  const lookupData = useContext(LookupContext);
  const [filter, setFilter] = useState({});
  const [showDescription, setDescription] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState();
  const [exportJobModal, setExportJobModal] = useState(false);
  const [invoiceStatus, setInvoiceStatus] = useState();
  const [exportLoading, setExportLoading] = useState();

  const fetchInvoices = useCallback((params) => {
    // Needed to set to avoid expanded items being called again on page change
    setData([]);
    setLoading(true);
    getInvoice(params).then((res) => {
      setLoading(false);
      setData(res?.data?.data);
      setMeta(res?.data?.meta);
    }).catch(() => {});
  }, []);

  useEffect(() => {
    fetchInvoices(filter);
  }, [fetchInvoices, filter]);

  useEffect(() => {
    getInvoiceHeader().then((res) => {
      setInvoiceStatus(res?.data);
    }).catch(() => {});
  }, []);

  const showDescriptionHandler = (jobId) => {
    setSelectedJobId(jobId);
    setDescription(true);
  };

  const viewPDFHandler = (link) => {
    if (link) {
      openInANewTab(link);
    }
  };

  const getClassName = (status) => {
    switch (status) {
      case 'paid': return 'completed';
      case 'past-due': return 'past-due';
      case 'unpaid': return 'unpaid';
      case 'partially-paid': return 'partially-paid';
      case 'voided': return 'voided';
      case 'payment-skipped': return 'voided2';
      case 'refunded': return 'refunded';
      default: return '';
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'paid': return <StatusCompletedIcon />;
      case 'unpaid': return <StatusUnpaidIcon />;
      case 'partially-paid': return <StatusPartiallyPaidIcon />;
      case 'payment-skipped': return <StatusPaymentSkippedIcon />;
      case 'past-due': return <StatusVoidIcon />;
      case 'voided': return <StatusVoidedIcon />;
      case 'refunded': return <StatusRefundedIcon />;
      default: return '';
    }
  };

  const exportHandler = (params, setError) => {
    setExportLoading(true);
    getExportInvoices(params).then((response) => {
      openInANewTab(response.data?.export_url);
      setExportLoading(false);
      setExportJobModal(false);
    }).catch((error) => {
      setExportLoading(false);
      catchHandler(error, setError, true);
      setExportJobModal(false);
    });
  };

  const onLinkClickHandler = (job) => {
    if (job?.external_job_source?.link) {
      openInANewTab(job?.external_job_source?.link);
    } else {
      showDescriptionHandler(job.job_id);
    }
  };

  const getTableRowClassNames = (rowData) => {
    const classNames = [];
    if (rowData?.is_make_ready) {
      classNames.push('make-ready-job-invoice');
    }
    return classNames.join(' ');
  };

  useEffect(() => {
    if (location?.state?.job_no) {
      setFilter({
        search_keyword: location?.state?.job_no,
      });
      window.history.replaceState({}, document.title);
    }
  }, [location?.state]);

  return (
    <React.Fragment>
      <HeaderComponent>
        <button className="excel-btn" type="button" onClick={() => setExportJobModal(true)}>
          <ExcelIcon className="mr-1" />
          Export to Excel
        </button>
      </HeaderComponent>
      <div className="invoice-filter">
        <Filter
          title="Invoices"
          HeaderIcon={() => <InvoicesIcon />}
          invoiceStatus={invoiceStatus}
          filters={[{
            filter_type: 'custom-input',
            name: 'search_keyword',
            placeholder: 'Search by Property Address / Invoice # / Lula Job # / Property Manager Job #',
            type: 'text',
            inputProps: {
              className: 'form-control form-control-resize',
            },
          },
          {
            filter_type: 'select',
            name: 'status',
            options: lookupData?.invoiceStatus ?? [],
            placeholder: 'Filter by Status',
            inputProps: {
              className: 'global-select global-select-resize',
            },
          },
          {
            filter_type: 'select',
            name: 'market_city',
            options: lookupData?.marketCities ?? [],
            placeholder: 'All Markets',
            type: 'text',
            inputProps: {
              className: 'global-select global-select-resize',
            },
          },
          {
            filter_type: 'date-range',
            name: 'dateRange',
            from_name: 'from_date',
            to_name: 'to_date',
          },
          {
            filter_type: 'switch',
            label: 'Make-readies only',
            name: 'show_make_ready_only',
          },
          {
            filter_type: 'clear',
            name: 'clear-filter',
          },
          ]}
          onFilter={(params) => setFilter((prev) => ({
            ...(pick(prev, ['per_page', 'sort'])),
            page: 1,
            ...params,
          }))}
          clearStateValues={{}}
          defaultValues={{
            search_keyword: location?.state?.job_no,
          }}
        />
      </div>

      <div className="data-card p-0 custom-data-card">
        <ListingComponent
          isLoading={loading}
          mappings={[
            {
              key: 'expand',
              label: '',
              type: 'expand',
            },
            {
              key: 'invoice_no',
              label: 'INVOICE #',
              render: (item) => (
                <React.Fragment>
                  <div className="invoice-number">
                    <button type="button" onClick={() => showDescriptionHandler(item.job_id)}>{item?.invoice_no}</button>
                    {item?.is_make_ready ? <div className="make-ready-job">Make-Ready</div> : ''}
                  </div>
                </React.Fragment>
              ),
            },
            {
              key: 'job_no',
              className: 'd-none d-xl-table-cell',
              label: 'LULA JOB #',
              render: (item) => (
                <React.Fragment>
                  <button type="button" className="link-btn" onClick={() => showDescriptionHandler(item.job_id)}>{item?.job_no}</button>
                </React.Fragment>
              ),
            },
            {
              key: 'mgr_job_no',
              className: 'd-none d-xl-table-cell',
              label: 'Prop. MGR JOB #',
              render: (item) => (
                <React.Fragment>
                  {item.external_job_source
                    ? (
                      <React.Fragment>
                        <button type="button" className="pm-link link-btn" onClick={() => onLinkClickHandler(item)}>
                          {item.external_job_source.reference_no ? (
                            <React.Fragment>
                              #
                              {item.external_job_source.reference_no}
                            </React.Fragment>
                          ) : ''}
                        </button>
                        {' '}
                        <span className="pm-title">{item.external_job_source.label}</span>
                      </React.Fragment>
                    )
                    : '–' }
                </React.Fragment>
              ),
            },
            {
              key: 'address',
              label: 'PROPERTY ADDRESS',
              className: 'd-none d-xl-table-cell',
              render: (item) => (
                <React.Fragment>
                  {item?.address?.split('\n').map((addressInfo) => (
                    <div key={generateRandomString()}>
                      {addressInfo}
                      <br />
                    </div>
                  ))}
                </React.Fragment>
              ),
            },
            {
              key: 'due_date',
              label: 'DUE DATE (NTE30)',
              className: 'd-none d-xl-table-cell text-center',
              sortable: true,
              sortKey: 'due',
              render: (item) => (
                <React.Fragment>
                  <div>
                    {item?.due_date}
                    <br />
                    {item?.due_days ? (
                      <span className={`past-due-days ${item?.due_days?.includes('past') ? 'warning' : ''}`}>
                        {item?.due_days}
                      </span>
                    ) : ''}
                  </div>
                </React.Fragment>
              ),
            },
            {
              key: 'status',
              label: 'STATUS',
              sortable: true,
              className: 'd-none d-sm-table-cell',
              sortKey: 'status',
              render: (item) => (
                <div className={`d-none d-sm-block status ${getClassName(item.status?.slug)}`}>
                  {getStatusIcon(item.status?.slug)}
                  {item?.status?.label}
                </div>
              ),
            },
            {
              key: 'invoice_amount',
              label: 'INVOICE AMOUNT',
              sortable: true,
              sortKey: 'total_price',
              className: 'd-none  d-sm-table-cell text-center',
              render: (item) => (
                <React.Fragment>
                  $
                  {item.invoice_amount}
                </React.Fragment>
              ),
            },
            {
              id: 'status_mob',
              key: 'status',
              label: 'STATUS',
              sortable: true,
              sortKey: 'status',
              className: 'd-table-cell d-sm-none text-center',
              render: (item) => (
                <div className={`d-block d-sm-none status ${getClassName(item?.status?.slug)}`}>
                  {getStatusIcon(item.status?.slug)}
                  {item?.status?.label}
                </div>
              ),
            },
            {
              key: 'invoice_mob',
              label: 'AMOUNT',
              sortable: true,
              sortKey: 'total_price',
              className: 'd-table-cell d-sm-none',
              render: (item) => (
                <React.Fragment>
                  $
                  {item.invoice_amount}
                </React.Fragment>
              ),
            },
            {
              key: 'invoice',
              label: 'INVOICE',
              sortable: false,
              className: 'd-none d-md-flex text-center d-xl-table-cell',
              render: (item) => (
                <React.Fragment>
                  {
                    item?.pdf_link ? (
                      <button type="button" className="pdf-btn" onClick={() => viewPDFHandler(item?.pdf_link)}>
                        <PortableDocumentIcon />
                        <span>PDF</span>
                      </button>
                    ) : (
                      'Not Available'
                    )
                  }
                </React.Fragment>
              ),
            },
          ]}
          dataSource={data}
          meta={meta}
          shouldPaginate
          className="global-table mb-0 invoice-table"
          onPageChange={(page, perPage) => setFilter((prev) => ({
            ...prev,
            page,
            per_page: perPage,
          }))}
          onSortChange={(sort) => {
            const newFilter = { ...filter };
            if (!isEmpty(sort)) {
              newFilter.sort = sort;
            } else {
              delete newFilter.sort;
            }
            setFilter(newFilter);
          }}
          primaryKey="job_id"
          renderExpanded={(job) => <JobDetails job={job} invoice />}
          getTableRowClassNames={(rowData) => getTableRowClassNames(rowData)}
        />
      </div>
      {
        showDescription && (
          <JobDescriptionModal
            showDescription={showDescription}
            toggle={() => setDescription(false)}
            jobId={selectedJobId}
          />
        )
      }
      {exportJobModal ? (
        <ExportModal
          closeModal={() => setExportJobModal(false)}
          exportHandler={exportHandler}
          title="Export Invoices"
          entity="Invoices"
          isLoading={exportLoading}
        />
      ) : ''}
    </React.Fragment>
  );
};
export default Invoices;
