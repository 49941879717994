import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Form,
  CustomInput,
  Button,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import Select, { components } from 'react-select';
import {
  NotificationsHeaderIcons,
  FilterIcon,
  ArchiveIcon,
  ChatBubbleBigIcon,
  NotificationBigIcon,
} from 'assets/images/svg-icons';
import HeaderComponent from 'components/shared/header-component';

const Placeholder = (props) => <components.Placeholder {...props} />;
const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

const perPage = [
  { value: '10', label: '10' },
  { value: '20', label: '20' },
  { value: '50', label: '50' },
];

const Notifications = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <React.Fragment>
      <HeaderComponent>
        <div className="archived-btn">
          Archived
          <CustomInput type="switch" id="exampleCustomSwitch" name="customSwitch" label="" />
        </div>
      </HeaderComponent>
      <div className="page-header page-header-with-filter">
        <div className="page-header-inner">
          <span>
            <NotificationsHeaderIcons />
          </span>
          <h3>Notifications</h3>
        </div>
        <Link to="#" className="filter-icon filters-added" onClick={toggle}>
          <FilterIcon />
          No Filters
        </Link>
      </div>
      <div className="data-card p-0">
        <Form className="global-filters global-filters-open-jobs">
          <Select
            options={options}
            className="global-select"
            components={{ Placeholder }}
            placeholder="Bulk Actions"
            styles={{
              placeholder: (base) => ({
                ...base,
                color: '#A6A6A6',
              }),
            }}
          />
          <Button color="secondary">Apply</Button>
        </Form>
        <div className="data-wrap">
          <Table responsive className="global-table notifications-table">
            <thead>
              <tr>
                <th aria-label="checkbox">
                  <CustomInput type="checkbox" id="1" />
                </th>
                <th>Subject</th>
                <th className="sortable">Date</th>
                <th className="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row" aria-label="checkbox">
                  <CustomInput type="checkbox" id="2" />
                </th>
                <td>
                  <div className="subject-outer">
                    <div className="due-days due">60</div>
                    <div className="invoice-status" />
                    Invoice&nbsp;
                    <button type="button" className="link-btn">#ABC3T6GH</button>
                    &nbsp;is 60 days past due.
                  </div>
                </td>
                <td>
                  Nov. 30, 2020 - 03:02pm
                </td>
                <td className="text-center">
                  <Button className="light-blue-btn" onClick={toggle}>
                    <ArchiveIcon />
                    Archive
                  </Button>
                </td>
              </tr>
              <tr>
                <th scope="row" aria-label="checkbox">
                  <CustomInput type="checkbox" id="3" />
                </th>
                <td>
                  <div className="subject-outer">
                    <div className="due-days due">30</div>
                    Invoice&nbsp;
                    <button type="button" className="link-btn">#ABC3T6GH</button>
                    &nbsp;is 60 days past due.
                  </div>
                </td>
                <td>
                  Nov. 30, 2020 - 03:02pm
                </td>
                <td className="text-center">
                  <Button className="light-blue-btn">
                    <ArchiveIcon />
                    Archive
                  </Button>
                </td>
              </tr>
              <tr className="">
                <th scope="row" aria-label="checkbox">
                  <CustomInput type="checkbox" id="4" />
                </th>
                <td>
                  <div className="subject-outer">
                    <div className="due-days disabled">15</div>
                    Invoice&nbsp;
                    <button type="button" className="link-btn">#ABC3T6GH</button>
                    &nbsp;is 60 days past due.
                  </div>
                </td>
                <td>
                  Nov. 30, 2020 - 03:02pm
                </td>
                <td className="text-center">
                  <div className="archived">
                    <div className="archived-data">
                      <ArchiveIcon />
                      Archived!
                    </div>
                    <Link to="#">Undo</Link>
                  </div>
                </td>
              </tr>
              <tr className="selected">
                <th scope="row" aria-label="checkbox">
                  <CustomInput type="checkbox" id="5" checked />
                </th>
                <td>
                  <div className="subject-outer">
                    <div className="due-days due">3</div>
                    Invoice&nbsp;
                    <button type="button" className="link-btn">#ABC3T6GH</button>
                    &nbsp;is 60 days past due.
                  </div>
                </td>
                <td>
                  Nov. 30, 2020 - 03:02pm
                </td>
                <td className="text-center">
                  <Button className="light-blue-btn">
                    <ArchiveIcon />
                    Archive
                  </Button>
                </td>
              </tr>
              <tr>
                <th scope="row" aria-label="checkbox">
                  <CustomInput type="checkbox" id="6" />
                </th>
                <td>
                  <div className="subject-outer">
                    <div className="due-days message">
                      <ChatBubbleBigIcon />
                    </div>
                    Invoice&nbsp;
                    <button type="button" className="link-btn">#ABC3T6GH</button>
                    &nbsp;is 60 days past due.
                  </div>
                </td>
                <td>
                  Nov. 30, 2020 - 03:02pm
                </td>
                <td className="text-center">
                  <Button className="light-blue-btn">
                    <ArchiveIcon />
                    Archive
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="global-pagination">
            <div className="page-count">Showing 1-5 of 200</div>
            <div className="pagination-outer">
              <Pagination aria-label="Page navigation example">
                <PaginationItem active>
                  <PaginationLink href="#">
                    1
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink href="#">
                    2
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink href="#">
                    3
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink href="#">
                    4
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                  <PaginationLink href="#">
                    5
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </div>
            <div className="per-page">
              <span>Rows Per Page:</span>
              <Select
                options={perPage}
                className="global-select"
                components={{ Placeholder }}
                placeholder=""
                styles={{
                  placeholder: (base) => ({
                    ...base,
                    color: '#A6A6A6',
                  }),
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        className="global-modal crop-image-modal"
      >
        <ModalHeader toggle={toggle}>Filter Results</ModalHeader>
        <ModalBody>
          <div className="divider" />
          <Form className="global-filters global-filters-open-jobs-mobile">
            <Input type="text" name="" id="" placeholder="Search by Address / Job / Tenant" />
            <Select
              options={options}
              className="global-select"
              components={{ Placeholder }}
              placeholder="Filter by Service"
              styles={{
                placeholder: (base) => ({
                  ...base,
                  color: '#A6A6A6',
                }),
              }}
            />
            <div className="date-picker">
              <span>Last 30 Days</span>
              <CalendarIcon />
            </div>
            <Button className="btn-clear">
              <i>
                <CloseIcon />
              </i>
              Clear
            </Button>
          </Form>
          <div className="filter-btn-outer">
            <Button color="secondary mr-md-4" onClick={toggle}>Close</Button>
            <Button color="primary">Apply</Button>
          </div>
        </ModalBody>

      </Modal> */}
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        className="global-modal notes-modal"
      >
        <ModalHeader toggle={toggle} />
        <ModalBody>
          <div className="notification-data">
            <div className="notification-icon">
              <NotificationBigIcon />
            </div>
            <h4>Archive selected notifications?</h4>
            <p>You can see all archived items using “Archived” toggle on top of the page.</p>
          </div>
          <div className="notification-btn-wrap">
            <Button color="primary" className="btn-block">Yes, Archive</Button>
            <Button color="secondary" className="btn-block" onClick={toggle}>No</Button>
          </div>
        </ModalBody>

      </Modal>
    </React.Fragment>
  );
};
export default Notifications;
