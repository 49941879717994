import React, {
  useState, useEffect, useCallback,
} from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { pick } from 'lodash';

import { getClosedMakeReadyJobs } from 'services/jobs';
import {
  StatusCompletedIcon,
  StatusVoidIcon,
  MakeReadyIcon,
  ClosedMakeReadyIcon,
} from 'assets/images/svg-icons';

import ListingComponent from 'components/shared/listing-component';
import JobDetails from 'components/shared/job-details';

import Filter from 'components/shared/filter/filter';

import JobDescriptionModal from 'components/shared/modals/job-description-modal';

const ClosedMakeReadies = (props) => {
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({});
  const [loading, setLoading] = useState(false);
  const { location } = props;
  const startDate = location?.state?.startDate || moment().subtract(119, 'days').format('YYYY-MM-DD');
  const endDate = location?.state?.endDate || moment().format('YYYY-MM-DD');
  const [filter, setFilter] = useState({
    from_date: startDate,
    to_date: endDate,
    ...(location?.state?.show_reviewed_jobs ? { show_reviewed_jobs: 1 } : {}),
  });
  const [showDescription, setDescription] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState();

  const url = useLocation();

  const fetchClosedJobs = useCallback((params) => {
    // Needed to set to avoid expanded items being called again on page change
    setData([]);
    setLoading(true);
    getClosedMakeReadyJobs(params).then((res) => {
      setLoading(false);
      setData(res?.data?.data);
      setMeta(res?.data?.meta);
    }).catch(() => {});
  }, []);

  useEffect(() => {
    fetchClosedJobs(filter);
  }, [fetchClosedJobs, filter]);

  const showDescriptionHandler = (jobId) => {
    setSelectedJobId(jobId);
    setDescription(true);
  };

  const getClassName = (status) => {
    switch (status) {
      case 'completed': return 'completed';
      case 'not-completed': return 'cancelled';
      case 'quote-accepted': return 'quote-completed';
      default: return '';
    }
  };

  useEffect(() => {
    if (url?.state?.job_no) {
      setFilter({
        search_keyword: url?.state?.job_no,
      });
      window.history.replaceState({}, document.title);
    }
  }, [url?.state]);

  return (
    <React.Fragment>
      <Filter
        title="Closed Make Ready Board"
        HeaderIcon={() => <ClosedMakeReadyIcon />}
        filters={[
          {
            filter_type: 'custom-input',
            name: 'search_keyword',
            placeholder: 'Search by Job/Address',
            type: 'text',
            inputProps: {
              className: 'form-control form-control-closed-jobs',
            },
          },
          {
            filter_type: 'date-range',
            name: 'dateRange',
            from_name: 'from_date',
            to_name: 'to_date',
          },
          {
            filter_type: 'clear',
            name: 'clear-filter',
          },
        ]}
        clearStateValues={{
          dateRange: {
            startDate,
            endDate,
            type: 'date-range',
          },
        }}
        defaultValues={{
          search_keyword: url?.state?.job_no,
          uniqueKey: url?.key,
          dateRange: {
            startDate,
            endDate,
            type: 'date-range',
          },
        }}
        onFilter={(params) => setFilter((prev) => ({
          ...pick(prev, ['per_page', 'sort']),
          page: 1,
          ...params,
        }))}
      />
      <div className="data-card p-0 custom-data-card closed-make-readies">
        <ListingComponent
          isLoading={loading}
          mappings={[
            {
              key: 'expand',
              label: '',
              type: 'expand',
            },
            {
              key: 'job_no',
              label: 'Job #',
              render: (item) => (
                <React.Fragment>
                  <div className="services">
                    <button
                      type="button"
                      className="link-btn"
                      onClick={() => showDescriptionHandler(item.job_id)}
                    >
                      {item.job_no}
                    </button>
                  </div>
                </React.Fragment>
              ),
            },
            {
              key: 'service_category',
              label: 'Service Type',
              className: 'd-none d-sm-table-cell',
              render: (item) => (
                <div className="services make_ready">
                  <MakeReadyIcon />
                  {item?.service_category}
                </div>
              ),
            },
            {
              key: 'address',
              label: 'PROPERTY ADDRESS',
              className: 'd-none d-xl-table-cell',
            },
            {
              key: 'status',
              label: 'STATUS',
              sortable: true,
              sortKey: 'status',
              className: 'd-none d-sm-table-cell sw-9',
              render: (item) => (
                <div
                  className={`d-none d-sm-flex status closed-make-readies ${getClassName(
                    item.status_group,
                  )}`}
                >
                  {item.status_group === 'not-completed' ? (
                    <StatusVoidIcon />
                  ) : (
                    <StatusCompletedIcon />
                  )}
                  {item.status}
                </div>
              ),
            },
            {
              id: 'status_mob',
              key: 'status',
              label: 'STATUS',
              sortable: true,
              sortKey: 'status',
              className: 'd-table-cell d-sm-none text-center',
              render: (item) => (
                <div
                  className={`d-block d-sm-none status ${getClassName(
                    item.status_group,
                  )}`}
                >
                  {item.status_group === 'not-completed' ? (
                    <StatusVoidIcon />
                  ) : (
                    <StatusCompletedIcon />
                  )}
                </div>
              ),
            },
            {
              key: 'bill',
              label: 'BILL',
              sortable: true,
              sortKey: 'bill',
              render: (item) => (
                <React.Fragment>
                  $
                  {item.bill}
                </React.Fragment>
              ),
            },
          ]}
          dataSource={data}
          meta={meta}
          shouldPaginate
          className="global-table"
          onPageChange={(page, perPage) => setFilter((prev) => ({
            ...prev,
            page,
            per_page: perPage,
          }))}
          onSortChange={(sort) => setFilter((prev) => ({ ...prev, sort }))}
          primaryKey="job_id"
          renderExpanded={(job) => <JobDetails job={job} makeReady />}
        />
      </div>
      {showDescription && (
        <JobDescriptionModal
          showDescription={showDescription}
          toggle={() => setDescription(false)}
          jobId={selectedJobId}
        />
      )}
    </React.Fragment>
  );
};
export default ClosedMakeReadies;
