import React, { useContext } from 'react';
import {
  Navbar,
  NavbarToggler,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';

import {
  ProfileIcon, OurPMIcon,
  LogoutIcon, LogoIcon,
} from 'assets/images/svg-icons';
import { logout } from 'services/auth';
import { AuthContext } from 'context/auth-context/auth-context';
import GlobalSearch from 'components/global-search/global-search';

const Header = () => {
  const [userDetails] = useContext(AuthContext);

  const sidemenuToggle = () => {
    document.getElementById('sidebarNav').classList.toggle('open');
  };
  const history = useHistory();

  const handleLogout = () => {
    logout().then(() => {
      localStorage.clear();
      if (window?.smartlook) {
        window.smartlook('anonymize');
      }
      history.push('/auth');
    });
  };
  return (
    <React.Fragment>
      <Navbar expand="lg" className="admin-navbar-head">
        <div className="nav-wrapper ml-lg-auto">
          <div className="logo-outer-xs">
            <Link to="/dashboard" className="sidebar-logo-outer">
              <LogoIcon />
              <h5>Customer Dashboard</h5>
            </Link>
          </div>
          <div className="d-flex ml-auto">
            <ul className="d-flex align-items-center header-dropdown-outer">
              <UncontrolledDropdown nav inNavbar className="d-none d-lg-block">
                <div className="header-button-section">
                  <div className="header-button-section--inner" id="header-portal-container" />
                </div>
              </UncontrolledDropdown>
              {/* <UncontrolledDropdown nav inNavbar className="d-none d-lg-block">
                <DropdownToggle nav className="header-notification">
                  <div className="header-notification--icon unread">
                    <NotificationsIcon />
                  </div>
                </DropdownToggle>
                <DropdownMenu right>
                  <div className="notifications-outer">
                    <div className="notifications--item">
                      <div className="notifications--item-type invoice">60</div>
                      <div className="notifications--item-data">
                        Invoice
                        <Link to="#">#ABC3T6GH</Link>
                        is 60 days past due.
                      </div>
                      <div className="notifications--item-timestamp">1d ago</div>
                    </div>
                    <div className="notifications--item">
                      <div className="notifications--item-type invoice">30</div>
                      <div className="notifications--item-data">
                        Invoice
                        <Link to="#">#ABC3T6GH</Link>
                        is 30 days past due.
                      </div>
                      <div className="notifications--item-timestamp">4w ago</div>
                    </div>
                    <div className="notifications--item">
                      <div className="notifications--item-type custom-message">
                      <ChatBubbleIcon /></div>
                      <div className="notifications--item-data">
                        Custom message from admin. Custom message from admin.
                        message from admin.Custom message…
                        <Link to="#">
                          Read More
                        </Link>
                      </div>
                      <div className="notifications--item-timestamp">2mos ago</div>
                    </div>
                    <div className="notifications--view-all">
                      <Link to="/notifications">View All Notifications</Link>
                    </div>
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown> */}
              <GlobalSearch />
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret className="header-profile">
                  <div className="profile-name">
                    <span className="d-none d-lg-block">
                      {userDetails && userDetails.first_name}
                      {' '}
                      {userDetails && userDetails.last_name}
                    </span>
                    <span className="d-xs-block d-sm-block d-md-block d-lg-none">
                      {userDetails && userDetails.first_name
                        && userDetails.first_name.charAt(0).toUpperCase()}
                      {userDetails && userDetails.last_name
                        && userDetails.last_name.charAt(0).toUpperCase()}
                    </span>
                  </div>
                </DropdownToggle>
                <DropdownMenu right>
                  <div className="user-info">
                    <div className="user-info--icon">
                      {userDetails && userDetails.profile_pic_url
                        ? (
                          <img
                            src={userDetails.profile_pic_url}
                            alt={`${userDetails.first_name} ${userDetails.last_name}`}
                          />
                        )
                        : (
                          <span>
                            {userDetails && userDetails.first_name
                              && userDetails.first_name.charAt(0).toUpperCase()}
                            {userDetails && userDetails.last_name
                              && userDetails.last_name.charAt(0).toUpperCase()}
                          </span>
                        )}
                    </div>
                    <div className="user-info--name_email">
                      <h4>
                        {userDetails && userDetails.first_name}
                        {' '}
                        {userDetails && userDetails.last_name}
                      </h4>
                      <h5>{userDetails && userDetails.email}</h5>
                    </div>
                  </div>
                  <div className="profile-menu--item">
                    <DropdownItem>
                      <Link to="/my-profile">
                        <ProfileIcon />
                        My Profile
                      </Link>
                    </DropdownItem>
                  </div>
                  {
                    (userDetails?.property_manager_type !== 'employee') && (
                      <div className="profile-menu--item">
                        <DropdownItem>
                          <Link to="/property-managers">
                            <OurPMIcon />
                            Our Property Managers
                          </Link>
                        </DropdownItem>
                      </div>
                    )
                  }
                  <div className="profile-menu--item">
                    <DropdownItem>
                      <button type="button" className="logout-btn logout-txt" onClick={handleLogout}>
                        <LogoutIcon />
                        Logout
                      </button>
                    </DropdownItem>
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
            </ul>
            <NavbarToggler data-toggle="offcanvas" onClick={sidemenuToggle} />
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  );
};

export default Header;
