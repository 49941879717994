import { request } from 'helpers/api';

export const getQuotes = (params) => (
  request({
    method: 'GET',
    url: '/api/quotes',
    params,
    cancel_token: 'getQuotes',
  })
);

export const getQuoteDetails = (quoteId) => (
  request({
    method: 'GET',
    url: `/api/quotes/${quoteId}`,
  })
);

export const getQuotePdfLink = (quoteId) => (
  request({
    method: 'GET',
    url: `/api/quotes/${quoteId}/generate_pdf_link`,
  })
);

export const updateQuoteStatus = (quoteId, params) => (
  request({
    method: 'PUT',
    url: `/api/quotes/${quoteId}`,
    params,
  })
);

export const getQuotesCount = (params) => (
  request({
    method: 'GET',
    url: '/api/quote-list',
    params,
  })
);
