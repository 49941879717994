import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Table,
  Button,
} from 'reactstrap';
import classNames from 'classnames';
import { isEmpty, size } from 'lodash';

import Loader from 'components/shared/loader';
import ImageSlider from 'components/shared/image-slider';
import ReviewModal from 'components/closed-jobs/review-modal';
import JobAppliancesTable from 'components/closed-jobs/job-appliance-table';
import ConfirmationModal from 'components/shared/modals/confirmation-modal';
import CustomerNotesModal from 'components/shared/modals/customer-notes-modal';
import JobDescriptionModal from 'components/shared/modals/job-description-modal';

import { getJobDetails, getExportMedia, getClosedMakeReadyJobDetails } from 'services/jobs';
import { DownloadIcon, GoBackIcon } from 'assets/images/svg-icons';
import RenderMaps from 'components/shared/map/map';
import JobRating from 'components/shared/job-rating';
import {
  openInANewTab, logHere, generateRandomString, formatCurrency,
} from 'helpers/utils';
import { getQuoteDetails, updateQuoteStatus } from 'services/quotes';

const residentIssuesBanner = {
  partially: {
    showResidentProblem: 'partially caused',
    residentProblemBannerClassName: 'partially-caused',
  },
  yes: {
    showResidentProblem: 'caused',
    residentProblemBannerClassName: 'fully-caused',
  },
};

const JobDetails = ({
  job, invoice, quotes, confirmIcon, setData, makeReady,
}) => {
  const [details, setDetails] = useState();
  const [showDescription, setDescription] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showReview, setReview] = useState(false);
  const [showImageSlider, setImageSlider] = useState(false);
  const [sliderImageArray, setSliderImageArray] = useState([]);
  const [collapse, setCollapse] = useState({});
  const [confirmModal, setConfirmModal] = useState({
    status: false,
    id: '',
    isApproved: false,
  });
  const [confirmButtonLoading, setConfirmButtonLoading] = useState(false);
  const [customerNotesModalData, setCustomerNotesModalData] = useState({});

  useEffect(() => {
    setLoading(true);
    if (quotes) {
      getQuoteDetails(job?.job_id)
        .then((res) => {
          setLoading(false);
          setDetails(res?.data);
        })
        .catch(() => {
          setLoading(false);
        });
    } else if (makeReady) {
      getClosedMakeReadyJobDetails(job?.job_id).then((res) => {
        setLoading(false);
        setDetails(res?.data);
      }).catch(() => {
        setLoading(false);
      });
    } else {
      getJobDetails(job?.job_id).then((res) => {
        setLoading(false);
        setDetails(res?.data);
      }).catch(() => {
        setLoading(false);
      });
    }
  }, [job.job_id, quotes, makeReady]);

  const onImageClickHandler = (images, img) => {
    const imageArrayForSlider = images.filter((image) => image !== img);
    imageArrayForSlider.unshift(img);
    setSliderImageArray(imageArrayForSlider);
    setImageSlider(true);
  };

  const exportMedia = (jobId, source) => {
    getExportMedia(jobId, source,
      { ...(makeReady ? { make_ready_media: true } : {}) }).then((response) => {
      logHere('export Media', response);
      openInANewTab(response.data?.export_url);
    }).catch(() => {});
  };

  const collapseHandler = (label) => {
    setCollapse((prev) => ({ ...prev, [label]: !prev[label] }));
  };

  const onLinkClickHandler = () => {
    if (details?.residential_info?.external_job_work?.link) {
      openInANewTab(details?.residential_info?.external_job_work?.link);
    } else if (details?.job_info?.external_job_source?.link) {
      openInANewTab(details?.job_info?.external_job_source?.link);
    } else {
      setDescription(true);
    }
  };
  const viewPDFHandler = (link) => {
    if (link) {
      openInANewTab(link);
    }
  };
  // const onConfirmHandle = (isApproved, jobId) => {
  //   setConfirmModal({ status: true, id: jobId, isApproved });
  // };

  const confirmModalHandler = () => {
    const payLoad = {
      status: confirmModal?.isApproved ? 'approve' : 'reject',
    };
    setConfirmButtonLoading(true);
    updateQuoteStatus(confirmModal.id, payLoad)
      .then(() => {
        setConfirmButtonLoading(false);
        setConfirmModal((prev) => ({
          ...prev,
          status: false,
        }));
        setData((prevData) => prevData.map((data) => {
          if (data.job_id === confirmModal.id) {
            return {
              ...data,
              quote_status: confirmModal?.isApproved
                ? 'Approved'
                : 'Rejected',
            };
          }
          return data;
        }));
        setDetails((prevDetails) => ({
          ...prevDetails,
          show_approve_disapprove_button: false,
        }));
      })
      .catch(() => {
        setConfirmButtonLoading(false);
        setConfirmModal((prev) => ({
          ...prev,
          status: false,
        }));
      });
  };

  const downloadAllEstimatePhotos = (link) => {
    if (link) {
      openInANewTab(link);
    }
  };

  const renderWorkOrder = (externalJobWork) => {
    if (externalJobWork?.reference_no) {
      return (
        <React.Fragment>
          <td>
            {externalJobWork?.label || ''}
            {' '}
            WO #
          </td>
          <td>
            <button type="button" className="link-btn" onClick={() => onLinkClickHandler()}>
              #
              {externalJobWork?.reference_no}
            </button>
          </td>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <td>PM Software</td>
        <td>Not Available</td>
      </React.Fragment>
    );
  };

  return (
    <div className="expanded-row--data">
      {loading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <Row>
            <Col md="12" lg="4">
              <div className="data-card closed-jobs accordion-xs">
                <div className="data-card--header sm-card with-border">
                  <h3>
                    <div
                      className="accordion-handle-xs"
                      onClick={() => collapseHandler('resident-info')}
                    >
                      <div
                        className={classNames('row-detail', 'without-bg', {
                          expanded: collapse['resident-info'],
                        })}
                      />
                    </div>
                    {quotes ? 'Resident Info' : 'Job Info'}
                  </h3>
                  {details?.has_go_back_option && (
                    <div className="goback-btn">
                      <GoBackIcon />
                      Go-Back Job
                    </div>
                  )}
                </div>
                <div
                  className={classNames({
                    'data-card-data': !collapse['resident-info'],
                  })}
                >
                  {makeReady ? (
                    <Table responsive className="small-table">
                      <tbody>
                        <tr>
                          <td>Address</td>
                          <td>{details?.job_info?.address || '-'}</td>
                        </tr>
                        <tr>
                          <td>Start Date</td>
                          <td>{details?.job_info?.start_date || '-'}</td>
                        </tr>
                        <tr>
                          <td>Anticipated Completion Date</td>
                          <td>
                            {details?.job_info?.delivery_date || '-'}
                          </td>
                        </tr>
                        {details?.job_info?.complete_date && (
                        <tr>
                          <td>Actual Completion Date</td>
                          <td>{details?.job_info?.complete_date || '-'}</td>
                        </tr>
                        )}
                        {details?.job_info?.cancelation_date && (
                        <tr className="cancel-job-error">
                          <td>Cancellation Date</td>
                          <td>{details?.job_info?.cancelation_date || '-'}</td>
                        </tr>
                        )}
                        {details?.job_info?.cancelation_reason && (
                        <tr className="cancel-job-error">
                          <td>Cancellation Reason</td>
                          <td>{details?.job_info?.cancelation_reason || '-'}</td>
                        </tr>
                        )}
                        <tr>
                          {renderWorkOrder(
                            details?.job_info?.external_job_source,
                          )}
                        </tr>
                      </tbody>
                    </Table>

                  ) : (
                    <Table responsive className="small-table">
                      <tbody>
                        <tr>
                          <td>Name</td>
                          <td>{details?.residential_info?.name}</td>
                        </tr>
                        <tr>
                          <td>Phone</td>
                          <td>
                            <a href={`tel:${details?.residential_info?.phone}`}>{details?.residential_info?.phone}</a>
                          </td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>
                            {details?.residential_info?.address
                              ?.split('\n')
                              .map((addressInfo) => (
                                <small key={generateRandomString()}>
                                  {addressInfo}
                                  <br />
                                </small>
                              ))}
                          </td>
                        </tr>
                        {details?.resident_owner_name
                          ? (
                            <tr>
                              <td>Owner</td>
                              <td>
                                {details?.resident_owner_name}
                              </td>
                            </tr>
                          ) : ''}
                        {details?.residential_info?.completion_date && (
                        <tr>
                          <td>Completion Date</td>
                          <td>{details?.residential_info?.completion_date}</td>
                        </tr>
                        )}
                        {details?.residential_info?.cancelation_date && (
                        <tr className="cancel-job-error">
                          <td>Cancellation Date</td>
                          <td>{details?.residential_info?.cancelation_date}</td>
                        </tr>
                        )}
                        {details?.residential_info?.cancelation_reason && (
                        <tr className="cancel-job-error">
                          <td>Cancellation Reason</td>
                          <td>{details?.residential_info?.cancelation_reason}</td>
                        </tr>
                        )}
                        <tr>
                          {renderWorkOrder(
                            details?.residential_info?.external_job_work,
                          )}
                        </tr>
                        {['partially', 'yes']?.includes(details?.is_resident_caused_problem) ? (
                          <tr className="resident-problem-row">
                            <td colSpan={2}>
                              <div className={`resident-problem-alert resident-problem-alert-${details?.is_resident_caused_problem}`}>
                                <span
                                  className={`resident-problem-icon resident-problem--${details?.is_resident_caused_problem}`}
                                >
                                  R
                                </span>
                                <div className="resident-problem-alert-content">
                                  <p className="resident-problem-alert-title">
                                    Resident
                                    {' '}
                                    <span>
                                      {residentIssuesBanner?.
                                        [details?.is_resident_caused_problem]
                                        ?.showResidentProblem}
                                    </span>
                                    {' '}
                                    the problem
                                  </p>
                                  {details?.resident_problem_description ? (
                                    <p>
                                      Note:
                                      {' '}
                                      {details?.resident_problem_description}
                                    </p>
                                  ) : ''}
                                </div>
                              </div>
                            </td>
                          </tr>
                        ) : ''}
                      </tbody>
                    </Table>
                  )}
                  {details?.has_customer_notes ? (
                    <div className="job-note-action">
                      <Button
                        className="btn btn-gray-outlined"
                        onClick={() => setCustomerNotesModalData({ data: job })}
                      >
                        Job Notes
                        {' '}
                        {details?.has_unread_customer_notes ? (
                          <span>
                           &nbsp;
                            (New message)
                          </span>
                        ) : ''}
                      </Button>
                    </div>
                  ) : ''}
                  {details?.pro_rating?.rating ? (
                    <div className="review">
                      <div className="review-left">
                        <span>Lula Pro Rating:</span>
                        <div className="stars">
                          <JobRating
                            rating={details?.pro_rating?.rating}
                            width="13"
                            height="13"
                          />
                        </div>
                        <span>{details?.pro_rating?.rating}</span>
                      </div>
                      <div className="review-right">
                        <button type="button" className="link-btn" onClick={() => setReview(true)}>
                          Read Review
                        </button>
                      </div>
                    </div>
                  ) : (
                    <React.Fragment>
                      {invoice ? (
                        <div className="review-not-available">
                          Review Not Available
                        </div>
                      ) : (
                        ''
                      )}
                    </React.Fragment>
                  )}
                </div>
              </div>
            </Col>
            <Col md="12" lg="4">
              <div className="data-card closed-jobs accordion-xs ">
                <div className="data-card--header sm-card with-border">
                  <h3>
                    <div
                      className="accordion-handle-xs"
                      onClick={() => collapseHandler('job-description')}
                    >
                      <div
                        className={classNames('row-detail', 'without-bg', {
                          expanded: collapse['job-description'],
                        })}
                      />
                    </div>
                    {quotes ? <React.Fragment>Quote Description Request</React.Fragment> : (
                      <React.Fragment>
                        {makeReady ? 'Make Ready Scope' : 'Job Description'}
                      </React.Fragment>
                    )}
                  </h3>
                </div>
                <div
                  className={classNames({
                    'data-card-data': !collapse['job-description'],
                  })}
                >
                  <ul className="job-description">
                    <li>{details?.description}</li>
                  </ul>
                  <div className="view-description d-none d-lg-flex">
                    <Button
                      color="secondary"
                      onClick={() => setDescription(true)}
                    >
                      View Full Description
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            {!makeReady ? (
              <Col md="12" lg="4">
                <div className="data-card closed-jobs accordion-xs data-card--estimate">
                  <div className="data-card--header sm-card with-border">
                    <h3>
                      <div
                        className="accordion-handle-xs"
                        onClick={() => collapseHandler('service-cost')}
                      >
                        <div
                          className={classNames('row-detail', 'without-bg', {
                            expanded: collapse['service-cost'],
                          })}
                        />
                      </div>
                      {quotes ? 'Estimate / Quote' : 'Services / Cost'}
                    </h3>
                  </div>
                  <div
                    className={classNames({
                      'data-card-data data-card-data--estimate':
                      !collapse['service-cost'],
                    })}
                  >
                    {quotes ? (
                      <React.Fragment>
                        <div className="estimate-table">
                          <div className="estimate-thead">
                            <div className="tr">
                              <div className="th">task</div>
                              <div className="th">cost</div>
                            </div>
                          </div>
                          <div className="estimate-data">
                            <div className="estimate-tbody">
                              {(details?.job_invoice_items || []).map(
                                (item) => (
                                  <div
                                    className="tr"
                                    key={item?.job_invoice_item_id}
                                  >
                                    <div className="td">
                                      {`Task #${item?.task_order}`}
                                    </div>
                                    <div className="td">
                                      {item?.total_price
                                        ? formatCurrency(item?.total_price, 2, 2)
                                        : ''}
                                    </div>
                                    <div className="td--details formatted-description">
                                      {item?.label}
                                    </div>
                                  </div>
                                ),
                              )}
                            </div>
                          </div>
                          <div className="estimate-tfoot">
                            <div className="tr">
                              {details?.job_invoice?.invoice_amount ? (
                                <div className="td">
                                  Total:
                                  {' '}
                                  {formatCurrency(details?.job_invoice?.invoice_amount, 2, 2)}
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </div>
                        {details?.quote_review_url ? (
                          <div className="estimate-cta">
                            <Button
                              color="primary"
                              onClick={() => {
                                openInANewTab(details?.quote_review_url);
                              }}
                            >
                              {details?.quote_status === 'Pending Approval' ? 'Review and Approve' : 'View Details'}
                            </Button>
                          </div>
                        ) : (
                          ''
                        )}
                      </React.Fragment>
                    ) : (
                      <Table
                        responsive
                        className="small-table small-table-pricing small-table-service-cost"
                      >
                        <tbody>
                          <React.Fragment>
                            <tr>
                              <td>
                                {details?.service_category}
                                {' '}
                                Service
                              </td>
                              <td>
                                $
                                {details?.job_invoice?.service}
                              </td>
                            </tr>
                            <tr>
                              <td>Parts and Materials</td>
                              <td>
                                $
                                {details?.job_invoice?.parts}
                              </td>
                            </tr>
                          </React.Fragment>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>Total</td>
                            <td>
                              $
                              {details?.job_invoice?.total}
                            </td>
                          </tr>
                        </tfoot>
                      </Table>
                    )}

                    {invoice && job?.pdf_link && (
                    <div className="view-description d-lg-flex">
                      <Button
                        color="secondary"
                        onClick={() => viewPDFHandler(job?.pdf_link)}
                      >
                        View Invoice
                      </Button>
                    </div>
                    )}
                    {/* {quotes && details?.show_approve_disapprove_button && (
                    <div className="action-button-wrapper">
                      <Button
                        color="primary"
                        className="approve-btn"
                        onClick={() => onConfirmHandle(true, job.job_id)}
                      >
                        Approve
                      </Button>
                      <Button
                        className="disapprove-btn"
                        onClick={() => onConfirmHandle(false, job.job_id)}
                      >
                        Disapprove
                      </Button>
                    </div>
                  )} */}
                  </div>
                </div>
              </Col>
            ) : (
              <Col xs="12" md="12" lg="4">
                <div className="map-section">
                  <h3 className="job-location-heading">
                    <div
                      className="accordion-handle-xs"
                      onClick={() => collapseHandler('map-section')}
                    >
                      <div
                        className={classNames('row-detail', 'without-bg', {
                          expanded: collapse['map-section'],
                        })}
                      />
                    </div>
                    Job Location
                  </h3>
                  <div
                    className={classNames({
                      'map-section-data': !collapse['map-section'],
                    })}
                  >
                    {details?.location?.latitude
                && details?.location?.longitude && (
                  <RenderMaps
                    latitude={details?.location?.latitude}
                    longitude={details?.location?.longitude}
                    height="330px"
                    width="100%"
                  />
                    )}
                  </div>
                </div>
              </Col>
            )}

          </Row>
          {!invoice && (
            <Row>
              <Col xs="12" md="12" lg="8">
                {details?.parent_job_service_notes ? (
                  <Row>
                    <Col>
                      <div className="data-card closed-jobs height-auto accordion-xs">
                        <div className="data-card--header sm-card with-border">
                          <h3>
                            <div
                              className="accordion-handle-xs"
                              onClick={() => collapseHandler('parent_job_service_notes')}
                            >
                              <div
                                className={classNames(
                                  'row-detail',
                                  'without-bg',
                                  { expanded: collapse.parent_job_service_notes },
                                )}
                              />
                            </div>
                            Service Notes
                          </h3>
                        </div>
                        <div
                          className={`service-notes ${classNames({
                            'data-card-data': !collapse.parent_job_service_notes,
                          })}`}
                        >
                          {details?.parent_job_service_notes}
                        </div>
                      </div>
                    </Col>
                  </Row>
                ) : ''}
                {!quotes ? (
                  <React.Fragment>
                    {!makeReady ? (
                      <Row>
                        <Col>
                          <div className="data-card closed-jobs height-auto accordion-xs">
                            <div className="data-card--header sm-card with-border">
                              <h3>
                                <div
                                  className="accordion-handle-xs"
                                  onClick={() => collapseHandler('service-notes')}
                                >
                                  <div
                                    className={classNames(
                                      'row-detail',
                                      'without-bg',
                                      { expanded: collapse['service-notes'] },
                                    )}
                                  />
                                </div>
                                Lula Service Notes
                              </h3>
                            </div>
                            <div
                              className={`service-notes ${classNames({
                                'data-card-data': !collapse['service-notes'],
                              })}`}
                            >
                              {size(details?.service_notes) > 0 ? (
                                <ul className="job-description">
                                  {details?.service_notes?.map((serviceNote) => (
                                    <li key={serviceNote?.job_status_id || generateRandomString()}>
                                      <React.Fragment>
                                        {serviceNote?.date}
                                        -
                                        <span
                                          className={serviceNote?.status_label?.toLowerCase()}
                                        >
                                          {serviceNote?.status_label}
                                          {' '}
                                          Note:
                                        </span>
                                        {serviceNote?.note}
                                      </React.Fragment>
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                <div className="no-data-found">
                                  No data found.
                                </div>
                              ) }
                            </div>
                          </div>
                        </Col>
                      </Row>
                    ) : ''}
                    {size(details?.location_appliances) > 0 ? (
                      <Row>
                        <Col>
                          <div className="data-card closed-jobs height-auto accordion-xs">
                            <div className="data-card--header sm-card with-border">
                              <h3>
                                <div
                                  className="accordion-handle-xs"
                                  onClick={() => collapseHandler('appliance')}
                                >
                                  <div
                                    className={`row-detail without-bg ${collapse?.appliance ? 'expanded' : ''}`}
                                  />
                                </div>
                                {details?.service_category}
                              </h3>
                            </div>
                            <div className={`job-appliance-table ${!(collapse?.appliance) ? 'data-card-data' : ''}`}>
                              <JobAppliancesTable
                                isHvacUpdatesApplicable={details?.is_hvac_updates_applicable}
                                dataSource={details?.location_appliances}
                                serviceCategory={details?.service_category}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    ) : ''}
                    <Row>
                      <Col xs="12" md="12" lg="6">
                        <div className="data-card closed-jobs height-auto accordion-xs">
                          <div className="data-card--header sm-card with-border">
                            <h3>
                              <div
                                className="accordion-handle-xs"
                                onClick={() => collapseHandler('before-photos')}
                              >
                                <div
                                  className={classNames(
                                    'row-detail',
                                    'without-bg',
                                    { expanded: collapse['before-photos'] },
                                  )}
                                />
                              </div>
                              Before Photos
                            </h3>
                            {size(details?.media?.before) > 0 && (
                              <Button
                                className="download-all-btn"
                                type="button"
                                onClick={() => exportMedia(job?.job_id, 'before')}
                              >
                                <DownloadIcon />
                                Download All
                              </Button>
                            )}
                          </div>
                          <div
                            className={classNames('image-outer', {
                              'data-card-data': !collapse['before-photos'],
                            })}
                          >
                            {size(details?.media?.before) > 0
                              ? details?.media?.before?.map((img) => (
                                <button key={img?.job_media_id} type="button" aria-label="before images">
                                  <img
                                    src={img?.thumbnail_url}
                                    alt=""
                                    width="70px"
                                    height="70px"
                                    onClick={() => onImageClickHandler(
                                      details?.media?.before,
                                      img,
                                    )}
                                  />
                                </button>
                              )) : <p className="no-data-found">No images found.</p>}
                          </div>
                        </div>
                      </Col>
                      <Col xs="12" md="12" lg="6">
                        <div className="data-card closed-jobs height-auto accordion-xs">
                          <div className="data-card--header sm-card with-border">
                            <h3>
                              <div
                                className="accordion-handle-xs"
                                onClick={() => collapseHandler('after-photos')}
                              >
                                <div
                                  className={classNames(
                                    'row-detail',
                                    'without-bg',
                                    { expanded: collapse['after-photos'] },
                                  )}
                                />
                              </div>
                              After Photos
                            </h3>
                            {size(details?.media?.after) > 0 && (
                              <Button
                                className="download-all-btn"
                                type="button"
                                onClick={() => exportMedia(job?.job_id, 'after')}
                              >
                                <DownloadIcon />
                                Download All
                              </Button>
                            )}
                          </div>
                          <div
                            className={classNames('image-outer', {
                              'data-card-data': !collapse['after-photos'],
                            })}
                          >
                            {size(details?.media?.after) > 0 ? details?.media?.after?.map((img) => (
                              <button key={img?.job_media_id} type="button" aria-label="after images">
                                <img
                                  src={img?.thumbnail_url}
                                  alt=""
                                  width="70px"
                                  height="70px"
                                  onClick={() => onImageClickHandler(
                                    details?.media?.after,
                                    img,
                                  )}
                                />
                              </button>
                            )) : <p className="no-data-found">No images found.</p>}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                ) : (
                  <Row>
                    <Col>
                      <div className="data-card closed-jobs height-auto accordion-xs">
                        <div className="data-card--header sm-card with-border">
                          <h3>
                            <div
                              className="accordion-handle-xs"
                              onClick={() => collapseHandler('after-photos')}
                            >
                              <div
                                className={classNames(
                                  'row-detail',
                                  'without-bg',
                                  { expanded: collapse['after-photos'] },
                                )}
                              />
                            </div>
                            Estimate Photos
                          </h3>
                          {size(
                            details?.job_invoice?.job_invoice_items_attachments,
                          ) > 0 && (
                            <Button
                              className="download-all-btn"
                              type="button"
                              onClick={() => {
                                downloadAllEstimatePhotos(
                                  details?.job_invoice?.attachment_download_link,
                                );
                              }}
                            >
                              <DownloadIcon />
                              Download All
                            </Button>
                          )}
                        </div>
                        <div
                          className={classNames('image-outer', {
                            'data-card-data': !collapse['after-photos'],
                          })}
                        >
                          {details?.job_invoice?.job_invoice_items_attachments?.map(
                            (img) => img?.thumbnail_url && (
                            <button key={img?.job_media_id} type="button" aria-label="before images">
                              <img
                                src={img?.thumbnail_url}
                                alt=""
                                width="70px"
                                height="70px"
                                onClick={() => onImageClickHandler(
                                  details?.job_invoice
                                    ?.job_invoice_items_attachments,
                                  img,
                                )}
                              />
                            </button>
                            ),
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
              {!makeReady ? (
                <Col xs="12" md="12" lg="4">
                  <div className="map-section">
                    <h3 className="job-location-heading">
                      <div
                        className="accordion-handle-xs"
                        onClick={() => collapseHandler('map-section')}
                      >
                        <div
                          className={classNames('row-detail', 'without-bg', {
                            expanded: collapse['map-section'],
                          })}
                        />
                      </div>
                      Job Location
                    </h3>
                    <div
                      className={classNames({
                        'map-section-data': !collapse['map-section'],
                      })}
                    >
                      {details?.location?.latitude
                      && details?.location?.longitude && (
                        <RenderMaps
                          latitude={details?.location?.latitude}
                          longitude={details?.location?.longitude}
                          height="330px"
                          width="100%"
                        />
                      )}
                    </div>
                  </div>
                </Col>
              ) : ''}

            </Row>
          )}
        </React.Fragment>
      )}
      {showDescription && (
        <JobDescriptionModal
          toggle={() => setDescription(false)}
          jobId={job?.job_id}
        />
      )}
      {showReview && (
        <ReviewModal
          showReview={showReview}
          toggle={() => setReview(false)}
          jobData={{ jobId: details?.job_id, jobNo: details?.job_no }}
        />
      )}
      {showImageSlider && (
        <ImageSlider
          showImageSlider={showImageSlider}
          toggle={() => setImageSlider(false)}
          imageArray={sliderImageArray}
        />
      )}
      {confirmModal?.status && (
        <ConfirmationModal
          modal={confirmModal?.status}
          toggle={() => setConfirmModal((prev) => ({
            ...prev,
            status: false,
          }))}
          title={`${
            confirmModal.isApproved ? 'Approve' : 'Disapprove'
          } This Quote ?`}
          confirmMessage={`${
            confirmModal.isApproved
              ? 'By approving this quote we will immediately move forward with scheduling.'
              : 'Are you sure that you want to disapprove this quote ?'
          }`}
          handler={confirmModalHandler}
          successButtonText={`Yes, ${
            confirmModal.isApproved ? 'Approve' : 'Disapprove'
          }`}
          cancelButtonText="No"
          confirmIcon={confirmIcon}
          isLoading={confirmButtonLoading}
        />
      )}
      {!isEmpty(customerNotesModalData)
        ? (
          <CustomerNotesModal
            toggle={() => {
              setCustomerNotesModalData({});
              setDetails((prev) => ({
                ...prev,
                has_unread_customer_notes: false,
              }));
            }}
            jobId={job?.job_id}
          />
        ) : ''}
    </div>
  );
};

export default JobDetails;
